var service = {};

if (process.env.NODE_ENV === 'production') {
    service.API_URL = 'https://edunnect-hrms-api.teamfeeltech.com';
    // service.API_URL = 'https://hrms-react-client.vercel.app';
} else {
    service.API_URL = 'http://192.168.29.109:5003';
}

export default service;
