// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getPromotion = createAsyncThunk('getPromotionAllData/getPromotion', async () => {
    try {
        let response = await axios.get('/Promotion', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getDesignationByEmployeeOption = createAsyncThunk('getPromotionAllData/getTypeOption', async (data) => {
    try {
        let response = await axios.get('/Promotion/designation-by-employee/' + data?.id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deletePromotion = createAsyncThunk('getPromotionAllData/deletePromotion', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/Promotion/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'EmployeeType delete Successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createPromotion = createAsyncThunk('getPromotionAllData/createPromotion', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/Promotion', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'EmployeeType added Successfully.');
        await dispatch(getPromotion());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updatePromotion = createAsyncThunk('getPromotionAllData/updatePromotion', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/Promotion/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'EmployeeType update Successfully.');
        await dispatch(getPromotion());
        return Promise.resolve(response);
    } catch {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updatePromotionStatus = createAsyncThunk('getPromotionAllData/updatePromotionStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/EmployeeType/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status Updated Succesfully');
        await dispatch(getType());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const promotionSlice = createSlice({
    name: 'getPromotionAllData',
    initialState: {
        promotion: [],
        designationByEmployeeOption: []
    },
    reducers: {
        removeDesignationByEmployeeOption: (state) => {
            state.designationByEmployeeOption = [];
        }
    },

    extraReducers: (builder) => {
        builder.addCase(getPromotion.fulfilled, (state, action) => {
            state.promotion = action?.payload;
            return;
        });
        builder.addCase(getDesignationByEmployeeOption.fulfilled, (state, action) => {
            state.designationByEmployeeOption = action?.payload;
            return;
        });
    }
});
export const { removeDesignationByEmployeeOption } = promotionSlice.actions;

export default promotionSlice.reducer;
