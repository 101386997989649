import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// axios import
import axios from 'axios';

// service object import
import service from './constant';

// auth config import
import authConfig from '@src/auth/config.js';

import { handleLogout } from '@store/authentication';

import { getPermission } from '@store/permission';
import { useDispatch } from 'react-redux';

const instance = axios.create({
    baseURL: service.API_URL + '/api/v1'
});

const AxiosInterceptor = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        // ** Request Interceptor
        const reqInterceptors = instance.interceptors.request.use(
            (config) => {
                // ** Get token from localStorage
                const accessToken = localStorage.getItem(authConfig.storageTokenKeyName);
                const branchvalue = localStorage.getItem('Branch');

                // ** If token is present add it to request's Authorization Header
                if (accessToken) {
                    // ** eslint-disable-next-line no-param-reassign
                    config.headers.Authorization = `Bearer ${JSON.parse(accessToken)}`;
                }
                if (branchvalue != null) {
                    // ** eslint-disable-next-line no-param-reassign
                    config.headers.Branch = ` ${branchvalue}`;
                }

                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        // ** Add request/response interceptor
        const resInterceptors = instance.interceptors.response.use(
            (response) => {
                return response;
            },
            async (error) => {
                // ** const { config, response: { status } } = error
                const { response } = error;

                // ** if (status === 403)
                if (response && response.status === 403) {
                    dispatch(getPermission());
                    navigate('/dashboard');
                    return Promise.reject(error);
                }

                // network error or session time out
                if (error?.code === 'ERR_NETWORK' && error.message.includes('Network Error')) {
                    dispatch(handleLogout());
                    navigate('/login');
                }

                // ** if (status === 404)
                if (response && response.status === 404) {
                    return Promise.reject(error);
                }

                // ** if (status === 401)
                if (response && response.status === 401) {
                    dispatch(handleLogout());
                    navigate('/login');
                    // return (window.location.href = '/login');
                    return Promise.reject(error);
                }

                if (response && response.status === 503) {
                    navigate('/under-maintenance');
                    return Promise.reject(error);
                }

                // error message for bolb response
                if (response?.config?.responseType && response?.config?.responseType == 'blob') {
                    const jsonData = await convertBlobToJson(response.data);
                    response.data = jsonData;
                }

                return Promise.reject(error);
            }
        );
        return () => {
            instance.interceptors.request.eject(reqInterceptors);
            instance.interceptors.response.eject(resInterceptors);
        };
    }, []);

    return;
};
const convertBlobToJson = (data) => {
    return new Promise((resolve, reject) => {
        try {
            var reader = new FileReader();
            reader.onload = function (event) {
                var blobData = event.target.result;
                var errorData = JSON.parse(blobData);
                return resolve(errorData);
            };
            reader.readAsText(data);
        } catch (err) {
            return reject(err);
        }
    });
};
export default instance;

export { AxiosInterceptor };
