// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getBranch = createAsyncThunk('getBranchAllData/getBranch', async () => {
    try {
        let response = await axios.get('/Branch', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getBranchOption = createAsyncThunk('getBranchAllData/getBranchOption', async () => {
    try {
        let response = await axios.get('/Branch?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getBranchById = createAsyncThunk('getBranchAllData/getBranchById', async (id) => {
    try {
        let response = await axios.get('/Branch/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteBranch = createAsyncThunk('getBranchAllData/deleteBranch', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/Branch/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Branch deleted successfully.');
        await dispatch(getBranch());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createBranch = createAsyncThunk('getBranchAllData/createBranch', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/Branch', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Branch added succesfully.');
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateBranch = createAsyncThunk('getBranchAllData/updateBranch', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/Branch/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Branch updated succesfully.');
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateBranchStatus = createAsyncThunk('getBranchAllData/updateBranchStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/branch/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully !');
        return Promise.resolve(response);
    } catch {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const branchSlice = createSlice({
    name: 'getBranchAllData',
    initialState: {
        branch: [],
        branchData: {},
        getBranchOption: []
    },

    extraReducers: (builder) => {
        builder.addCase(getBranch.fulfilled, (state, action) => {
            state.branch = action?.payload;
            return;
        });
        builder.addCase(getBranchById.fulfilled, (state, action) => {
            state.branchData = action?.payload;
            return;
        });
        builder.addCase(getBranchOption.fulfilled, (state, action) => {
            state.getBranchOption = action?.payload;
            return;
        });
    }
});

export default branchSlice.reducer;
