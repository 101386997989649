// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';
import { toast } from 'react-toastify';
import { handleActiveTab } from '../../redux/Tabs';
import queryString from 'query-string';

//** All Employee Api
export const downloadTxtFile = createAsyncThunk('getAllEmployeeData/downloadTxtFile', async (id) => {
    try {
        let response = await axios.get('/employee/security-token/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const ScreenShotBufferTime = createAsyncThunk('getAllEmployeeData/ScreenShotBufferTime', async (data) => {
    const query = queryString.stringify(data);
    try {
        let response = await axios.get('/employee/screenshot-buffer?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const employeeLogin = createAsyncThunk('getAllEmployeeData/employeeLogin', async (id) => {
    try {
        let response = await axios.get('/Authentication/user-wise-login/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const userLogin = createAsyncThunk('getAllEmployeeData/userLogin', async (id) => {
    try {
        let response = await axios.get('/Authentication/employee-wise-login/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getEmployee = createAsyncThunk('getAllEmployeeData/getEmployee', async (data = null, { dispatch }) => {
    try {
        let response = await axios.get('/Employee', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        await dispatch(handleActiveTab('1'));
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getReportingToEmployee = createAsyncThunk('getAllEmployeeData/getReportingToEmployee', async (data = null, { dispatch }) => {
    try {
        let response = await axios.get('/Employee/reporting-to', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        await dispatch(handleActiveTab('1'));
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getReportingToForEmployee = createAsyncThunk('getAllEmployeeData/getReportingToForEmployee', async (data, { dispatch }) => {
    try {
        let response = await axios.get('/Employee/reporting-to?employeeId=' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        await dispatch(handleActiveTab('1'));
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getReportingToEmployeeOption = createAsyncThunk(
    'getAllEmployeeData/getReportingToEmployeeOption',
    async (data = null, { dispatch }) => {
        try {
            let response = await axios.get('/Employee/reporting-to?option=true', {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            await dispatch(handleActiveTab('1'));
            return Promise.resolve(response?.data);
        } catch (err) {
            console.log('err', err);
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return Promise.reject(err);
        }
    }
);

export const getEmployeeEvaluation = createAsyncThunk('getAllEmployeeData/getEmployeeEvaluation', async (data = null, { dispatch }) => {
    try {
        let response = await axios.get('/EmployeeEvaluation', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // await dispatch(handleActiveTab('1'));
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getEmployeeEvaluationById = createAsyncThunk('getAllEmployeeData/getEmployeeEvaluationById', async (id) => {
    try {
        let response = await axios.get('/EmployeeEvaluation/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getPeople = createAsyncThunk('getAllEmployeeData/getPeople', async () => {
    try {
        let response = await axios.get('/Employee/people', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // await dispatch(handleActiveTab('1'));
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getExeWiseYear = createAsyncThunk('getAllEmployeeData/getExeWiseYear', async (data) => {
    const query = queryString.stringify(data);
    try {
        let response = await axios.get('/EmployeeLeave/exp-wise-year?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // await dispatch(handleActiveTab('1'));
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getLeaveCurrentYear = createAsyncThunk('getAllEmployeeData/getLeaveCurrentYear', async () => {
    try {
        let response = await axios.get('/EmployeeLeave/current-year-leave', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // await dispatch(handleActiveTab('1'));
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getPeopleOption = createAsyncThunk('getAllEmployeeData/getPeopleOption', async () => {
    try {
        let response = await axios.get('/Employee/people?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getStarredEmployee = createAsyncThunk('getAllEmployeeData/getStarredEmployee', async () => {
    try {
        let response = await axios.get(`/Employee/starred-employee`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createStarredEmployee = createAsyncThunk('getAllEmployeeData/createStarredEmployee', async (data, { dispatch }) => {
    try {
        let response = await axios.post(
            '/Employee/starred-employee/' + data
            // ,
            //  {
            //     headers: {
            //         'Content-Type': 'application/json'
            //     }
            // }
        );
        toast.success(response?.data?.message || 'Employee Starred Successfully');
        await dispatch(getPeopleOption());
        await dispatch(getPeople());
        await dispatch(getStarredEmployee());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getEmployeeSalaryRevision = createAsyncThunk('getAllEmployeeData/getEmployeeSalaryRevision', async (data) => {
    const query = queryString.stringify(data);
    try {
        let response = await axios.get('/Salary/salary-revision?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // await dispatch(getEmployeeSalaryById({ employeeId: data }));
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getEmployeeHirarchy = createAsyncThunk('getAllEmployeeData/getEmployeeHirarchy', async () => {
    try {
        let response = await axios.get('/employee/hierarchy', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getEmployeeTeamHirarchy = createAsyncThunk('getAllEmployeeData/getEmployeeTeamHirarchy', async () => {
    try {
        let response = await axios.get('/employee/team-hierarchy', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getWhoIsIn = createAsyncThunk('getAllEmployeeData/getWhoIsIn', async (data) => {
    try {
        let response = await axios.get('/Attendance/who-is-in?date=' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getEmployeeSalary = createAsyncThunk('getAllEmployeeData/getEmployeeSalary', async (data = null, { dispatch }) => {
    try {
        let response = await axios.get('/IncomeCategory?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getEmployeeOption = createAsyncThunk('getAllEmployeeData/getEmployeeOption', async () => {
    try {
        let response = await axios.get('/Employee?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteEmployee = createAsyncThunk('getAllEmployeeData/deleteEmployee', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/Employee/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Employee Delete Successfully.');
        await dispatch(getEmployee());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteEmployeeEvaluation = createAsyncThunk('getAllEmployeeData/deleteEmployeeEvaluation', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/EmployeeEvaluation/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Employee Delete Successfully.');
        await dispatch(getEmployeeEvaluation());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createEmployee = createAsyncThunk('getAllEmployeeData/createEmployee', async (data) => {
    try {
        let response = await axios.post('/Employee/add-employee-details', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Employee Add Successfully.');
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createEmployeeEvaluation = createAsyncThunk('getAllEmployeeData/createEmployeeEvaluation', async (data) => {
    try {
        let response = await axios.post('/EmployeeEvaluation', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Employee Add Successfully.');
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateEmployeeEvaluation = createAsyncThunk('getBranchAllData/updateEmployeeEvaluation', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/EmployeeEvaluation/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'EmployeeEvaluation updated succesfully.');
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createEmployeeSalary = createAsyncThunk('getAllEmployeeData/createEmployeeSalary', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/Salary', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Employee Add Successfully.');
        await dispatch(getEmployeeCurrentSalaryById(data?.employeeId));
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateEmployeeSalary = createAsyncThunk('getEmployeeByIdData/updateEmployeeSalary', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/salary', data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Salary updated succesfully.');
        await dispatch(getEmployeeCurrentSalaryById(data?.data?.employeeId));
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const createEmployeeIncrementSalary = createAsyncThunk(
    'getAllEmployeeData/createEmployeeIncrementSalary',
    async (data, { dispatch }) => {
        try {
            let response = await axios.post('/Salary/increment', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.message || 'Employee Add Successfully.');
            await dispatch(getEmployeeCurrentSalaryById(data?.employeeId));
            return Promise.resolve(response);
        } catch (err) {
            console.log('err', err);
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return Promise.reject(err);
        }
    }
);

export const createAdvancePayment = createAsyncThunk('getAllEmployeeData/createAdvancePayment', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/Salary/advance-payment', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Advance Payment added Successfully.');
        await dispatch(getEmployeeCurrentSalaryById(data?.employeeId));
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getEmployeeLoan = createAsyncThunk('getAllEmployeeData/getEmployeeLoan', async (id) => {
    try {
        let response = await axios.get('/Salary/loan/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const deleteLoan = createAsyncThunk('getAllEmployeeData/deleteLoan', async (id) => {
    try {
        let response = await axios.delete('/Salary/advance-payment/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Loan Data deleted successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const updateLoan = createAsyncThunk('getAllEmployeeData/updateLoan', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/Salary/advance-payment/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Loan Data Updated Succesfully');
        await dispatch(getEmployeeLoan(data?.data?.employeeId));
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getEmployeeEmis = createAsyncThunk('getAllEmployeeData/getEmployeeEmis', async (id) => {
    try {
        let response = await axios.get('/Salary/emis/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//** Employee By Id Api

// export const getEmployeeById = createAsyncThunk('getEmployeeByIdData/getEmployeeById', async (id, { dispatch }) => {
//     try {
//         let response = await axios.get('/Employee/' + id, {
//             headers: {
//                 'Content-Type': 'application/json'
//             }
//         });
//         await dispatch(getEmployeeRemarks(id));
//         return Promise.resolve(response?.data);
//     } catch (err) {
//         console.log('err', err);
//         toast.error(err?.response?.data?.message || 'Something went wrong!');
//         return Promise.reject(err);
//     }
// });
export const getPersonalandContactdetailsById = createAsyncThunk(
    'getAllEmployeeData/getPersonalandContactdetailsById',
    async (tempEmpId, { dispatch }) => {
        try {
            let response = await axios.get(`/Employee/personal-and-contact?employeeId=${tempEmpId}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return Promise.resolve(response?.data);
        } catch (err) {
            console.log('err', err);
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return Promise.reject(err);
        }
    }
);
export const getDocumentsById = createAsyncThunk('getAllEmployeeData/getDocumentsById', async (tempEmpId, { dispatch }) => {
    try {
        let response = await axios.get(`/Employee/documents?employeeId=${tempEmpId}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getBankDetailsById = createAsyncThunk('getAllEmployeeData/getBankDetailsById', async (tempEmpId, { dispatch }) => {
    try {
        let response = await axios.get(`/Employee/bank-details?employeeId=${tempEmpId}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getOfficeDetailsById = createAsyncThunk('getAllEmployeeData/getOfficeDetailsById', async (tempEmpId, { dispatch }) => {
    try {
        let response = await axios.get(`/Employee/office-details?employeeId=${tempEmpId}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getEmployeeSalaryById = createAsyncThunk('getAllEmployeeData/getEmployeeSalaryById', async (data) => {
    try {
        let response = await axios.get('/Salary/' + data?.employeeId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getEmployeeCurrentSalaryById = createAsyncThunk(
    'getAllEmployeeData/getEmployeeCurrentSalaryById',
    async (data, { dispatch }) => {
        try {
            let response = await axios.get('/Salary/current-salary/' + data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            // await dispatch(getEmployeeSalaryById({ employeeId: data }));
            return Promise.resolve(response?.data);
        } catch (err) {
            console.log('err', err);
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return Promise.reject(err);
        }
    }
);
export const getEmployeeCurrentSalaryBySalaryId = createAsyncThunk(
    'getAllEmployeeData/getEmployeeCurrentSalaryBySalaryId',
    async (data, { dispatch }) => {
        try {
            let response = await axios.get('/Salary/salary-by-id/' + data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            // await dispatch(getEmployeeSalaryById({ employeeId: data }));
            return Promise.resolve(response?.data);
        } catch (err) {
            console.log('err', err);
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return Promise.reject(err);
        }
    }
);
export const updateEmployee = createAsyncThunk('getEmployeeByIdData/updateEmployee', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/Employee/personal-details/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Employee updated succesfully.');
        await dispatch(getPersonalandContactdetailsById(data?.id));
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateStatus = createAsyncThunk('getEmployeeByIdData/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/Employee/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status Updated Succesfully');
        await dispatch(getEmployee());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getEmployeeStatus = createAsyncThunk('getEmployeeRemarksData/getEmployeeStatus', async (tempEmpId) => {
    try {
        let response = await axios.get(`/Employee/Status?employeeId=${tempEmpId}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

// export const updateProfessionalAcadmicsDetails = createAsyncThunk(
//     'getEmployeeByIdData/updateProfessionalAcadmicsDetails',
//     async (data, { dispatch }) => {
//         try {
//             let response = await axios.put('/Employee/professionalAndAcadmicsDetails/' + data.id, data?.data, {
//                 headers: {
//                     'Content-Type': 'application/json'
//                 }
//             });
//             toast.success(response?.data?.message || 'Employee updated succesfully.');
//             await dispatch(getEmployeeById(data?.id));
//             return Promise.resolve(response);
//         } catch (err) {
//             console.log('err', err);
//             toast.error(err?.response?.data?.message || 'Something went wrong!');
//             return Promise.reject(err);
//         }
//     }
// );

//** employee education
export const getEducationDetail = createAsyncThunk('getAllEmployeeEducationData/getEducationDetail', async (tempEmpId, { dispatch }) => {
    try {
        let response = await axios.get(`/EmployeeEducation/get-employee-education?employeeId=${tempEmpId}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // await dispatch(getEmployeeById(tempEmpId));  // call get employee education
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createEducationCourseDetails = createAsyncThunk(
    'getAllEmployeeEducationData/createEducationCourseDetails',
    async (data, { dispatch }) => {
        try {
            let response = await axios.post('/EmployeeEducation/add-employee-education-course', data?.data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.message || 'Employee course Add Successfully.');
            await dispatch(getEducationDetail(data?.empId));
            return Promise.resolve(response);
        } catch (err) {
            console.log('err', err);
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return Promise.reject(err);
        }
    }
);

//update course
export const updateEducationCourseDetails = createAsyncThunk(
    'getAllEmployeeEducationData/updateEducationCourseDetails',
    async (data, { dispatch }) => {
        try {
            let response = await axios.put('/EmployeeEducation/edit-employee-education-course/' + data?.id, data?.data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.message || ' Employee Course Updated Succesfully');
            return Promise.resolve(response);
        } catch (err) {
            console.log('err', err);
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return Promise.reject(err);
        }
    }
);

export const createEducationWorkDetails = createAsyncThunk(
    'getAllEmployeeEducationData/createEducationWorkDetails',
    async (data, { dispatch }) => {
        try {
            let response = await axios.post('/EmployeeEducation/add-employee-education-work', data?.data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.message || 'Employee work Add Successfully.');
            await dispatch(getEducationDetail(data?.empId));
            return Promise.resolve(response);
        } catch (err) {
            console.log('err', err);
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return Promise.reject(err);
        }
    }
);

export const updateEducationWorkDetails = createAsyncThunk(
    'getAllEmployeeEducationData/updateEducationWorkDetails',
    async (data, { dispatch }) => {
        try {
            let response = await axios.put('/EmployeeEducation/edit-employee-education-work/' + data.id, data?.data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.message || 'Employee Updated Succesfully');
            await dispatch(getEducationDetail(data?.empId));
            return Promise.resolve(response);
        } catch (err) {
            console.log('err', err);
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return Promise.reject(err);
        }
    }
);
export const deleteEmployeeEducation = createAsyncThunk(
    'getAllEmployeeEducationData/deleteEmployeeEducation',
    async (data, { dispatch }) => {
        try {
            let response = await axios.delete('/EmployeeEducation/delete-employee-education-work/' + data.deleteId, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.message || 'Employee Delete Successfully.');
            await dispatch(getEducationDetail(data.empId));
            return Promise.resolve(response?.data);
        } catch (err) {
            console.log('err', err);
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return Promise.reject(err);
        }
    }
);

export const updateContactDetails = createAsyncThunk('getEmployeeByIdData/updateContactDetails', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/Employee/contact-details/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Employee updated succesfully.');
        await dispatch(getPersonalandContactdetailsById(data?.id));
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateBankDetails = createAsyncThunk('getEmployeeByIdData/updateBankDetails', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/Employee/bank-details/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Employee updated succesfully.');
        await dispatch(getBankDetailsById(data?.id));
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateOfficialDetails = createAsyncThunk('getEmployeeByIdData/updateOfficialDetails', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/Employee/offcial-details/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Employee updated succesfully.');
        await dispatch(getOfficeDetailsById(data?.id));
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const removeProfilePictures = createAsyncThunk('getEmployeeByIdData/removeProfilePicture', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/Employee/remove-profile/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Employee updated succesfully.');
        await dispatch(getPersonalandContactdetailsById(data?.id));
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

// Document tabs----------------------------------

export const getKycDocumentName = createAsyncThunk('getKycDocumentData/getKycDocumentName', async () => {
    try {
        let response = await axios.get('/KycDocMaster?Status=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getEmployeeKycDoc = createAsyncThunk('getKycDocumentData/getEmployeeKycDoc', async () => {
    try {
        let response = await axios.get('/EmployeeKycDoc', {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createEmployeeKycDoc = createAsyncThunk('getKycDocumentData/createEmployeeKycDoc', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/EmployeeKycDoc', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Document Created succesfully.');
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateEmployeeKycDoc = createAsyncThunk('getKycDocumentData/updateEmployeeKycDoc', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/EmployeeKycDoc/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Document updated succesfully.');
        await dispatch(getPersonalandContactdetailsById(data?.empId));
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateEmpKycDocKycStatus = createAsyncThunk('getKycDocumentData/updateEmpKycDocKycStatus', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/EmployeeKycDoc/kycstatus/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated succesfully.');
        await dispatch(getEmployeeRemarks(data?.empId));
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getEmployeeRemarks = createAsyncThunk('getEmployeeRemarksData/getEmployeeRemarks', async (id) => {
    try {
        let response = await axios.get('/Remark/get-remarks/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getOfficeTimeLine = createAsyncThunk('getEmployeeRemarksData/getofficeTimeLine', async (data) => {
    try {
        const query = queryString.stringify(data);

        let response = await axios.get('/Employee/employment-timeline?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateApplicationStatusRemarks = createAsyncThunk(
    'getApplicationStatusData/getApplicationStatusRemarks',

    async (data, { dispatch }) => {
        try {
            let response = await axios.put('/Remark/application-status/' + data?.id, data?.data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.message || 'Status updated succesfully.');
            dispatch(getPersonalandContactdetailsById(data?.id));
            return Promise.resolve(response?.data);
        } catch (err) {
            console.log('err', err);
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return Promise.reject(err);
        }
    }
);

export const updateEmployeeRemarks = createAsyncThunk('getEmployeeByIdData/updateEmployeeRemarks', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/Remark/remark-status/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated succesfully.');
        await dispatch(getEmployeeStatus(data?.id)); //open this after work
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getLeaveRule = createAsyncThunk('getAllEmployeeData/getLeaveRule', async (tempEmpId) => {
    try {
        // const query = queryString.stringify(data);

        let response = await axios.get(`/EmployeeLeave?employeeId=${tempEmpId}`, {
            headers: {
                'Content-Type': 'application/json',
                IsAdmin: true
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getCurrentYearLeave = createAsyncThunk('getAllEmployeeData/getCurrentYearLeave', async (tempEmpId) => {
    try {
        // const query = queryString.stringify(data);

        let response = await axios.get(`/EmployeeLeave/current-year-leave?employeeId=${tempEmpId}`, {
            headers: {
                'Content-Type': 'application/json',
                IsAdmin: true
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getLeaveRules = createAsyncThunk('getAllEmployeeData/getLeaveRules', async (data) => {
    try {
        const query = queryString.stringify(data);

        let response = await axios.get('/EmployeeLeave?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getChartLeave = createAsyncThunk('getAllEmployeeData/getChartLeave', async (data) => {
    const query = queryString.stringify(data);
    try {
        let response = await axios.get('/employeeleave/chart-of-employee-leave?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (error) {
        console.log('err', error);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(error);
    }
});

export const getHistoryChartLeave = createAsyncThunk('getAllEmployeeData/getHistoryChartLeave', async (data) => {
    const query = queryString.stringify(data);
    try {
        let response = await axios.get('/employeeleave/history-of-employee-leave?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(JSON.parse(response?.data));
    } catch (error) {
        console.log('err', error);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(error);
    }
});

export const getTeamOnChartLeave = createAsyncThunk('getAllEmployeeData/getTeamOnChartLeave', async (data) => {
    const query = queryString.stringify(data);
    try {
        let response = await axios.get('/leaverequest/leave-chart-month-wise?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (error) {
        console.log('err', error);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(error);
    }
});

export const getTeamOnLeave = createAsyncThunk('getAllEmployeeData/getTeamOnLeave', async (data) => {
    const query = queryString.stringify(data);
    try {
        let response = await axios.get('/leaverequest/leave-month-wise?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (error) {
        console.log('err', error);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(error);
    }
});

export const createLeaveRule = createAsyncThunk('getAllEmployeeData/createLeaveRule', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/EmployeeLeave', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Leave Add Successfully.');
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateLeaveRule = createAsyncThunk('getAllEmployeeData/updateLeaveRule', async (data) => {
    try {
        let response = await axios.put('/EmployeeLeave/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Leave Rule updated succesfully.');
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//Salary Slip for employee tab
export const getSalarySlip = createAsyncThunk('getAllEmployeeData/getSalarySlip', async (id) => {
    try {
        let response = await axios.get(`/Salary/all-paid-salary-slip?id=${id}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createEmployeeSalarySlip = createAsyncThunk('getAllEmployeeData/createEmployeeSalarySlip', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/Salary/employee-salary-slip', data, {
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Salary Slip Add Successfully.');
        await dispatch(getSalarySlip(data?.employeeId));
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createReportEmployeeSalarySlip = createAsyncThunk(
    'getAllEmployeeData/createEmployeeSalarySlip',
    async (data, { dispatch }) => {
        try {
            let response = await axios.post('/Salary/report-employee-salary-slip', data, {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.message || 'Salary Slip Add Successfully.');
            await dispatch(getSalarySlip(data?.employeeId));
            return Promise.resolve(response);
        } catch (err) {
            console.log('err', err);
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return Promise.reject(err);
        }
    }
);

export const getSalarySlipPdf = async (id) => {
    try {
        let response = await axios.get(`/Salary/employee-salary-slip?id=${id}`, {
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/pdf'
            }
        });
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
};
export const addEmployeeLeave = createAsyncThunk('getAllEmployeeData/addEmployeeLeave', async (data) => {
    try {
        let response = await axios.post('/EmployeeLeave/employee-leave', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Leave Add Successfully.');
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const addEmployeeTransferLeave = createAsyncThunk('getAllEmployeeData/addEmployeeTransferLeave', async (data) => {
    try {
        let response = await axios.post('/EmployeeLeave/leave-transfer', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Leave Add Successfully.');
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getPreviewSalarySlip = createAsyncThunk('getAllEmployeeData/getPreviewSalarySlip', async (data) => {
    try {
        let response = await axios.post('/Salary/preview-salary-slip', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getEmployeePreviewSalarySlip = createAsyncThunk('getAllEmployeeData/getEmployeePreviewSalarySlip', async (data) => {
    try {
        let response = await axios.post('/Salary/employee-preview-salary-slip', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getSeniorEmployee = createAsyncThunk('getAllEmployeeData/getSeniorEmployee', async (data = null, { dispatch }) => {
    try {
        let response = await axios.get('/Employee/senior-employee', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        await dispatch(handleActiveTab('1'));
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getSeniorEmployeeOption = createAsyncThunk('getAllEmployeeData/getSeniorEmployeeOption', async (data = null, { dispatch }) => {
    try {
        let response = await axios.get('/Employee/senior-employee?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        await dispatch(handleActiveTab('1'));
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const employeeSlice = createSlice({
    name: 'getAllEmployeeData',
    initialState: {
        employee: [],
        // employeeDetail: {},
        employeeStatus: {},
        employeePersonalandContactdetails: {},
        kycDocumentMasterDetail: [],
        employeeKycDocDetail: [],
        employeeDocuments: [],
        employeeBankDetails: [],
        employeeOfficeDetails: [],
        employeeEducation: [],
        employeeRemarks: [],
        employeeOption: [],
        leaveRule: [],
        salary: [],
        employeesalary: [],
        currentEmployeesalary: [],
        currentEmployeesalaryById: [],
        salarySlipData: [],
        salarySlipPdf: [],
        previewSalarySlipData: [],
        employeePreviewSalarySlipData: [],
        EmployeeLoan: [],
        EmployeeEmis: [],
        employeeEvaluation: [],
        chartLeave: [],
        historyChartLeave: [],
        teamOnChartLeave: [],
        teamOnLeave: [],
        peopleOption: [],
        starredEmployee: [],
        people: [],
        employeeSalaryRevision: [],
        employeeWhoIsIn: [],
        reportingToEmployee: [],
        reportingToForEmployee: [],
        reportingToEmployeeOption: [],
        seniorEmployee: [],
        seniorEmployeeOption: [],
        employeeHirarchy: [],
        employeeTeamHirarchy: [],
        exeWiseYear: [],
        leaveRules: [],
        currentYearLeave: [],
        leaveCurrentYear: []
    },

    reducers: {
        removeEmployee: (state) => {
            state.employeeOption = [];
        },
        removesalaryslip: (state) => {
            state.previewSalarySlipData = [];
        },
        removeEmployeesalaryslip: (state) => {
            state.employeePreviewSalarySlipData = [];
        },
        removeTeamOnLeave: (state) => {
            state.teamOnLeave = [];
        },
        removeTeamOnChartLeave: (state) => {
            state.teamOnChartLeave = [];
        },
        removeExeYear: (state) => {
            state.exeWiseYear = [];
        },
        removeLeaveRules: (state) => {
            state.leaveRules = [];
        }
    },

    extraReducers: (builder) => {
        builder.addCase(getEmployee.fulfilled, (state, action) => {
            state.employee = action?.payload;
            state.employeeDetail = {};
            state.employeePersonalandContactdetails = {};
            state.employeeStatus = {};

            return;
        });
        // builder.addCase(getEmployeeById.fulfilled, (state, action) => {
        //     state.employeeDetail = action?.payload;
        //     return;
        // });
        builder.addCase(getEmployeeStatus.fulfilled, (state, action) => {
            state.employeeStatus = action?.payload;
            return;
        });
        builder.addCase(getExeWiseYear.fulfilled, (state, action) => {
            state.exeWiseYear = action?.payload;
            return;
        });
        builder.addCase(getPersonalandContactdetailsById.fulfilled, (state, action) => {
            state.employeePersonalandContactdetails = action?.payload;
            return;
        });
        builder.addCase(getDocumentsById.fulfilled, (state, action) => {
            state.employeeDocuments = action?.payload;
            return;
        });
        builder.addCase(getBankDetailsById.fulfilled, (state, action) => {
            state.employeeBankDetails = action?.payload;
            return;
        });
        builder.addCase(getOfficeDetailsById.fulfilled, (state, action) => {
            state.employeeOfficeDetails = action?.payload;
            return;
        });

        builder.addCase(getKycDocumentName.fulfilled, (state, action) => {
            state.kycDocumentMasterDetail = action?.payload;
            return;
        });
        builder.addCase(getEmployeeKycDoc.fulfilled, (state, action) => {
            state.employeeKycDocDetail = action?.payload;
            return;
        });
        builder.addCase(getEducationDetail.fulfilled, (state, action) => {
            state.employeeEducation = action?.payload;
            return;
        });
        builder.addCase(getEmployeeRemarks.fulfilled, (state, action) => {
            state.employeeRemarks = action?.payload;
            return;
        });
        builder.addCase(getEmployeeOption.fulfilled, (state, action) => {
            state.employeeOption = action?.payload;
            return;
        });
        builder.addCase(getLeaveRule.fulfilled, (state, action) => {
            state.leaveRule = action?.payload;
            return;
        });
        builder.addCase(getLeaveRules.fulfilled, (state, action) => {
            state.leaveRules = action?.payload;
            return;
        });
        builder.addCase(getCurrentYearLeave.fulfilled, (state, action) => {
            state.currentYearLeave = action?.payload;
            return;
        });
        builder.addCase(getLeaveCurrentYear.fulfilled, (state, action) => {
            state.leaveCurrentYear = action?.payload;
            return;
        });
        builder.addCase(getEmployeeSalary.fulfilled, (state, action) => {
            state.salary = action?.payload;
            return;
        });
        builder.addCase(getEmployeeSalaryById.fulfilled, (state, action) => {
            state.employeesalary = action?.payload;
            return;
        });
        builder.addCase(getEmployeeCurrentSalaryById.fulfilled, (state, action) => {
            state.currentEmployeesalary = action?.payload;
            return;
        });
        builder.addCase(getEmployeeCurrentSalaryBySalaryId.fulfilled, (state, action) => {
            state.currentEmployeesalaryById = action?.payload;
            return;
        });
        builder.addCase(getSalarySlip.fulfilled, (state, action) => {
            state.salarySlipData = action?.payload;
            return;
        });
        builder.addCase(getEmployeeLoan.fulfilled, (state, action) => {
            state.EmployeeLoan = action?.payload;
            return;
        });
        builder.addCase(getEmployeeEmis.fulfilled, (state, action) => {
            state.EmployeeEmis = action?.payload;
            return;
        });
        builder.addCase(getPreviewSalarySlip.fulfilled, (state, action) => {
            state.previewSalarySlipData = action?.payload;
            return;
        });
        builder.addCase(getEmployeePreviewSalarySlip.fulfilled, (state, action) => {
            state.employeePreviewSalarySlipData = action?.payload;
            return;
        });
        builder.addCase(getEmployeeEvaluation.fulfilled, (state, action) => {
            state.employeeEvaluation = action?.payload;
            return;
        });
        builder.addCase(getChartLeave.fulfilled, (state, action) => {
            state.chartLeave = action?.payload;
            return;
        });
        builder.addCase(getHistoryChartLeave.fulfilled, (state, action) => {
            state.historyChartLeave = action?.payload;
            return;
        });
        builder.addCase(getTeamOnChartLeave.fulfilled, (state, action) => {
            state.teamOnChartLeave = action?.payload;
            return;
        });
        builder.addCase(getTeamOnLeave.fulfilled, (state, action) => {
            state.teamOnLeave = action?.payload;
            return;
        });
        builder.addCase(getPeople.fulfilled, (state, action) => {
            state.people = action?.payload;
            return;
        });
        builder.addCase(getPeopleOption.fulfilled, (state, action) => {
            state.peopleOption = action?.payload;
            return;
        });
        builder.addCase(getStarredEmployee.fulfilled, (state, action) => {
            state.starredEmployee = action?.payload;
            return;
        });
        builder.addCase(getEmployeeSalaryRevision.fulfilled, (state, action) => {
            state.employeeSalaryRevision = action?.payload;
            return;
        });
        builder.addCase(getWhoIsIn.fulfilled, (state, action) => {
            state.employeeWhoIsIn = action?.payload;
            return;
        });
        builder.addCase(getReportingToEmployee.fulfilled, (state, action) => {
            state.reportingToEmployee = action?.payload;
            return;
        });
        builder.addCase(getReportingToForEmployee.fulfilled, (state, action) => {
            state.reportingToForEmployee = action?.payload;
            return;
        });
        builder.addCase(getReportingToEmployeeOption.fulfilled, (state, action) => {
            state.reportingToEmployeeOption = action?.payload;
            return;
        });
        builder.addCase(getSeniorEmployee.fulfilled, (state, action) => {
            state.seniorEmployee = action?.payload;
            return;
        });
        builder.addCase(getSeniorEmployeeOption.fulfilled, (state, action) => {
            state.seniorEmployeeOption = action?.payload;
            return;
        });
        builder.addCase(getEmployeeHirarchy.fulfilled, (state, action) => {
            state.employeeHirarchy = action?.payload;
            return;
        });
        builder.addCase(getEmployeeTeamHirarchy.fulfilled, (state, action) => {
            state.employeeTeamHirarchy = action?.payload;
            return;
        });
    }
});

export const {
    removeEmployee,
    removesalaryslip,
    removeLeaveRules,
    removeEmployeesalaryslip,
    removeExeYear,
    removeTeamOnLeave,
    removeTeamOnChartLeave
} = employeeSlice.actions;
export default employeeSlice.reducer;
