import { useState, useEffect } from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import service from './constant';
import showLoader from '@components/loader';
import { toast } from 'react-toastify';
// import { toast } from 'react-hot-toast';

// auth config import
const SRConnection = {};
const SignalRComponent = async () => {
    try {
        let accessToken = `${JSON.parse(localStorage.getItem('accessToken'))}`;
        const connection = new HubConnectionBuilder()
            .withUrl(service.API_URL + '/chatHub', {
                accessTokenFactory: () => accessToken
            })
            // .withAutomaticReconnect()
            .build();

        connection.on('ClientTestConnection', (IPAddress, Port, ExeId, token) => {
            console.log('ClientTestConnection ==>', IPAddress, Port, ExeId, token);
        });
        connection.on('ExeTestConnectionResult', (IsConnectedForSignalR) => {
            console.log('ExeTestConnectionResult ==>', IsConnectedForSignalR);
        });
        // connection.on('ExeSendEmployeeResult', (result) => {
        //     console.log('ExeTestConnectionResult ==>', result);
        // });
        // connection.on('ExeCollectAttendanceResult', (result) => {
        //     console.log('ExeCollectAttendanceResult ==>', result);
        // });

        await connection
            .start()
            .then(() => {
                console.log('HUB Connected');
                if (accessToken && accessToken != 'null') {
                    ConnectClient(accessToken, connection);
                }
            })
            .catch((err) => {
                // toast.error(err?.message || 'Something went wrong!');
                console.log('err', err?.message);
            });

        SRConnection.connection = connection;
    } catch (err) {
        console.log('Hub Connection Failed!!', err);
    }
};

const ConnectClient = async (accessToken, propsConnection = SRConnection.connection) => {
    return new Promise(async (resolve, reject) => {
        let currentState = propsConnection?.connection?._connectionState;
        if (currentState == 'Connected') {
            try {
                const connection = propsConnection;
                // console.log('Inside ConnectClients');
                if (connection) {
                    await connection.invoke('ClientConnection', accessToken);
                    console.log('Client Connected');
                    return resolve();
                }
            } catch (err) {
                console.log('Error in ConnectClient', err);
                return reject(err);
            }
        } else {
            // toast.error(currentState ? 'Hub Connection is ' + currentState : 'Not Connected to HUB');
            return reject(currentState ? 'Hub Connection is ' + currentState : 'Not Connected to HUB');
        }
    });
};

const SRDisconnect = async () => {
    let currentState = SRConnection?.connection?.connection?._connectionState;
    if (currentState == 'Connected') {
        try {
            const connection = SRConnection.connection;

            // console.log('Inside SRDisconnect');
            if (connection) {
                await connection.stop();
            }
        } catch (err) {
            console.log('Error in SRDisconnect', err);
        }
    } else {
        // toast.error(currentState ? 'Hub Connection is ' + currentState : 'Not Connected to HUB');
    }
};

const SRCollectAttendance = async (branchId) => {
    return new Promise(async (resolve, reject) => {
        console.log('Inside SRCollectAttendance');

        let accessToken = `${JSON.parse(localStorage.getItem('accessToken'))}`;
        let currentState = SRConnection?.connection?.connection?._connectionState;
        if (currentState == 'Connected') {
            try {
                const connection = SRConnection.connection;
                if (connection) {
                    await connection.invoke('ClientCollectAttendance', branchId, accessToken);
                    return resolve();
                }
            } catch (err) {
                console.log('Error in SRCollectAttendance', err);
                return reject(err);
            }
        } else {
            // toast.error(currentState ? 'Hub Connection is ' + currentState : 'Not Connected to HUB');
            return reject(currentState ? 'Hub Connection is ' + currentState : 'Not Connected to HUB');
        }
    });
};
const SRSendEmployee = async (branchId) => {
    return new Promise(async (resolve, reject) => {
        console.log('Inside SRSendEmployee');

        let accessToken = `${JSON.parse(localStorage.getItem('accessToken'))}`;
        let currentState = SRConnection?.connection?.connection?._connectionState;
        if (currentState == 'Connected') {
            try {
                const connection = SRConnection.connection;
                if (connection) {
                    await connection.invoke('ClientSendEmployee', branchId, accessToken);
                    return resolve();
                }
            } catch (err) {
                console.log('Error in SRCollectAttendance', err);
                return reject(err);
            }
        } else {
            // toast.error(currentState ? 'Hub Connection is ' + currentState : 'Not Connected to HUB');
            return reject(currentState ? 'Hub Connection is ' + currentState : 'Not Connected to HUB');
        }
    });
};

const SRClientTestConnection = async (data) => {
    console.log('Inside SRSendEmployee');

    return new Promise(async (resolve, reject) => {
        let accessToken = `${JSON.parse(localStorage.getItem('accessToken'))}`;
        let currentState = SRConnection?.connection?.connection?._connectionState;
        if (currentState == 'Connected') {
            try {
                const connection = SRConnection.connection;
                if (connection) {
                    await connection.invoke('ClientTestConnection', data?.IPAddress, data?.port, data?.branchId, accessToken);
                    return resolve();
                }
            } catch (err) {
                console.log('Error in SRCollectAttendance', err);
                return reject(err);
            }
        } else {
            // toast.error(currentState ? 'Hub Connection is ' + currentState : 'Not Connected to HUB');
            return reject(currentState ? 'Hub Connection is ' + currentState : 'Not Connected to HUB');
        }
    });
};

const SRCollectFingerprint = async (branchId) => {
    return new Promise(async (resolve, reject) => {
        console.log('Inside SRCollectFingerprint');

        let accessToken = `${JSON.parse(localStorage.getItem('accessToken'))}`;
        let currentState = SRConnection?.connection?.connection?._connectionState;
        if (currentState == 'Connected') {
            try {
                const connection = SRConnection.connection;
                if (connection) {
                    await connection.invoke('ClientCollectFingerprint', branchId, accessToken);
                    return resolve();
                }
            } catch (err) {
                console.log('Error in SRCollectFingerprint', err);
                return reject(err);
            }
        } else {
            // toast.error(currentState ? 'Hub Connection is ' + currentState : 'Not Connected to HUB');
            return reject(currentState ? 'Hub Connection is ' + currentState : 'Not Connected to HUB');
        }
    });
};
const SRSynchronizeFingerPrint = async (branchId) => {
    return new Promise(async (resolve, reject) => {
        console.log('Inside ClientSynchronizeFingerPrint');

        let accessToken = `${JSON.parse(localStorage.getItem('accessToken'))}`;
        let currentState = SRConnection?.connection?.connection?._connectionState;
        if (currentState == 'Connected') {
            try {
                const connection = SRConnection.connection;
                if (connection) {
                    await connection.invoke('ClientSynchronizeFingerPrint', branchId, accessToken);
                    return resolve();
                }
            } catch (err) {
                console.log('Error in ClientSynchronizeFingerPrint', err);
                return reject(err);
            }
        } else {
            // toast.error(currentState ? 'Hub Connection is ' + currentState : 'Not Connected to HUB');
            return reject(currentState ? 'Hub Connection is ' + currentState : 'Not Connected to HUB');
        }
    });
};
export {
    SRConnection,
    ConnectClient,
    SRDisconnect,
    SRCollectAttendance,
    SRSendEmployee,
    SRClientTestConnection,
    SRCollectFingerprint,
    SRSynchronizeFingerPrint
};

export default SignalRComponent;
